import { useState } from 'react';
import useForm from '~/client/hooks/form/useForm';
import useAppContext from '~/shared/context/AppContext/useAppContext';
import AuthStepsEnum from '../../AuthStepsEnum';

const useStepByStepAuthProvider = () => {
  const [step, setStep] = useState(AuthStepsEnum.EmailOrUsername);
  const [authClearanceId, setAuthClearanceId] = useState('');
  const [showInvalidCredentials, setShowInvalidCredentials] = useState(false);
  const [loginSuccessful, setLoginSuccessful] = useState(false);
  const [isStubSms2fa, setIsStubSms2fa] = useState(false);

  const {
    control,
    errors,
    required,
    handleSubmit,
    setError,
    setErrors,
    clearErrors,
    formState,
    optional,
    getValues,
    setValue,
    register,
    watch,
  } = useForm<AuthFormValues>({
    validationMode: 'all',
    defaultValues: { uses2FA: false },
  });

  const {
    captchaConfig: { isRegistrationCaptchaRequired, isLoginCaptchaRequired },
  } = useAppContext();
  const [registrationCaptchaRequired, setRegistrationCaptchaRequired] =
    useState(isRegistrationCaptchaRequired);
  const [loginCaptchaRequired, setLoginCaptchaRequired] = useState(
    isLoginCaptchaRequired,
  );
  const isEmail = new RegExp('^(.+)@(.+)$', 'g').test(watch('username'));

  return {
    step,
    isEmail,
    registrationCaptchaRequired,
    loginCaptchaRequired,
    authClearanceId,
    showInvalidCredentials,
    isStubSms2fa,
    loginSuccessful,
    control,
    errors,
    required,
    optional,
    formState,
    getValues,
    setValue,
    register,
    watch,
    handleSubmit,
    setError,
    setErrors,
    clearErrors,
    setStep,
    setRegistrationCaptchaRequired,
    setLoginCaptchaRequired,
    setAuthClearanceId,
    setShowInvalidCredentials,
    setIsStubSms2fa,
    setLoginSuccessful,
  };
};

export default useStepByStepAuthProvider;
