type KeyOf<F> = keyof F;
type Error<F> = [field: KeyOf<F>, message: string];

const parseErrors = <F>(errors?: GenericErrorType[]) =>
  errors?.reduce((acc, error) => {
    const { params, message } = error ?? {};
    const field = (params?.[0]?.value ?? 'global') as KeyOf<F>;
    const newError: Error<F> = [field, message ?? ''];
    return [...acc, newError];
  }, [] as Error<F>[]);

export default parseErrors;
