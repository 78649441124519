import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import useFeature from '~/client/hooks/feature/useFeature';
import useSite from '~/client/hooks/useSite';
import getEnv from '~/shared/utils/getEnv';
import parse from '~/shared/utils/string/parse';

const CaptchaProvider: FC = ({ children }) => {
  const isRecaptchaV3Enabled = useFeature('isRecaptchaV3Enabled');
  const { site } = useSite();
  const recaptchaV3ApiKeys = parse(
    getEnv('RECAPTCHA_V3_API_KEY') || '{}',
  ) as Partial<Record<Site, string>>;
  const reCaptchaKey = recaptchaV3ApiKeys?.[site] || '';
  if (isRecaptchaV3Enabled) {
    return (
      <GoogleReCaptchaProvider reCaptchaKey={reCaptchaKey}>
        {children}
      </GoogleReCaptchaProvider>
    );
  }
  return <>{children}</>;
};

export default CaptchaProvider;
