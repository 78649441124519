import { MetaMaskProvider } from 'metamask-react';
import useStepByStepAuthProvider from './hooks/useStepByStepAuthProvider';
import StepByStepAuthContext from './StepByStepAuthContext';

const StepByStepAuthProvider: FC = ({ children }) => {
  const {
    step,
    isEmail,
    registrationCaptchaRequired,
    loginCaptchaRequired,
    authClearanceId,
    showInvalidCredentials,
    isStubSms2fa,
    loginSuccessful,
    control,
    errors,
    formState,
    getValues,
    setValue,
    register,
    watch,
    required,
    optional,
    handleSubmit,
    setError,
    setErrors,
    clearErrors,
    setStep,
    setRegistrationCaptchaRequired,
    setLoginCaptchaRequired,
    setAuthClearanceId,
    setShowInvalidCredentials,
    setIsStubSms2fa,
    setLoginSuccessful,
  } = useStepByStepAuthProvider();
  return (
    <MetaMaskProvider>
      <StepByStepAuthContext.Provider
        value={{
          step,
          isEmail,
          registrationCaptchaRequired,
          loginCaptchaRequired,
          authClearanceId,
          showInvalidCredentials,
          isStubSms2fa,
          loginSuccessful,
          control,
          errors,
          formState,
          getValues,
          setValue,
          register,
          watch,
          required,
          optional,
          handleSubmit,
          setError,
          setErrors,
          clearErrors,
          setStep,
          setRegistrationCaptchaRequired,
          setLoginCaptchaRequired,
          setAuthClearanceId,
          setShowInvalidCredentials,
          setIsStubSms2fa,
          setLoginSuccessful,
        }}
      >
        {children}
      </StepByStepAuthContext.Provider>
    </MetaMaskProvider>
  );
};

export default StepByStepAuthProvider;
