import { createContext, Dispatch, SetStateAction } from 'react';
import type {
  ChangeHandler,
  FieldError,
  Path,
  UseFormClearErrors,
  UseFormGetValues,
  UseFormHandleSubmit,
  UseFormRegister,
  UseFormSetError,
  UseFormSetValue,
  UseFormStateReturn,
  UseFormWatch,
  Control,
} from 'react-hook-form';
import AuthStepsEnum from '../AuthStepsEnum';

type Props = {
  step: string;
  isEmail: boolean;
  registrationCaptchaRequired: boolean;
  loginCaptchaRequired: boolean;
  authClearanceId: string;
  showInvalidCredentials: boolean;
  isStubSms2fa: boolean;
  loginSuccessful: boolean;
  control: Control<AuthFormValues>;
  errors?: Record<string, FieldError>;
  required: (
    field: Path<AuthFormValues>,
    isRequired?: boolean,
  ) => {
    onChange: ChangeHandler;
    'data-testid': string;
  };
  optional: (field: Path<AuthFormValues>) => {
    'data-testid': string;
  };
  formState?: UseFormStateReturn<AuthFormValues>;
  getValues?: UseFormGetValues<AuthFormValues>;
  setValue?: UseFormSetValue<AuthFormValues>;
  register?: UseFormRegister<AuthFormValues>;
  watch?: UseFormWatch<AuthFormValues>;
  handleSubmit: UseFormHandleSubmit<AuthFormValues>;
  setError?: UseFormSetError<AuthFormValues>;
  setErrors?: (errorsFragment: GenericErrorType[]) => void;
  clearErrors?: UseFormClearErrors<AuthFormValues>;
  setStep: Dispatch<SetStateAction<AuthStepsEnum>>;
  setRegistrationCaptchaRequired: Dispatch<SetStateAction<boolean>>;
  setLoginCaptchaRequired: Dispatch<SetStateAction<boolean>>;
  setAuthClearanceId: Dispatch<SetStateAction<string>>;
  setShowInvalidCredentials: Dispatch<SetStateAction<boolean>>;
  setIsStubSms2fa: Dispatch<SetStateAction<boolean>>;
  setLoginSuccessful: Dispatch<SetStateAction<boolean>>;
};

const StepByStepAuthContext = createContext<Props>({
  step: AuthStepsEnum.EmailOrUsername,
  isEmail: false,
  registrationCaptchaRequired: false,
  loginCaptchaRequired: false,
  authClearanceId: '',
  showInvalidCredentials: false,
  isStubSms2fa: false,
  loginSuccessful: false,
  handleSubmit: (_) => () => Promise.resolve(),
  required: (_) => ({
    onChange: () => Promise.resolve(),
    'data-testid': '',
  }),
  optional: (_) => ({
    'data-testid': '',
  }),
  setStep: (_) => null,
  setRegistrationCaptchaRequired: (_) => null,
  setLoginCaptchaRequired: (_) => null,
  setAuthClearanceId: (_) => null,
  setShowInvalidCredentials: (_) => null,
  setIsStubSms2fa: (_) => null,
  setLoginSuccessful: (_) => null,
  control: <Control<AuthFormValues>>{},
});

export default StepByStepAuthContext;
