// eslint-disable-next-line @typescript-eslint/no-explicit-any
const parse = <T = any>(value = '{}'): T => {
  try {
    return JSON.parse(value.replaceAll("'", '"')) as T;
  } catch (error) {
    console.error(error);
    return {} as T;
  }
};

export default parse;
