import { useCallback } from 'react';
import {
  DeepPartial,
  FieldValues,
  Path,
  UnpackNestedValue,
  // eslint-disable-next-line no-restricted-imports
  useForm as useReactHookForm,
  UseFormRegisterReturn,
  ValidationMode,
  RegisterOptions,
} from 'react-hook-form';
import parseErrors from '~/client/hooks/form/helpers/parseErrors';
import useTranslation from '../i18n/useTranslation';

type Args<T> = {
  defaultValues?: UnpackNestedValue<DeepPartial<T>>;
  validationMode?: keyof ValidationMode;
};

const useForm = <T extends FieldValues>({
  defaultValues,
  validationMode,
}: Args<T> = {}) => {
  const {
    control,
    register,
    setError,
    clearErrors,
    watch,
    handleSubmit,
    getValues,
    trigger,
    formState,
    setValue,
  } = useReactHookForm<T>({
    defaultValues,
    mode: validationMode ?? 'onChange',
    reValidateMode: 'onChange',
    shouldUnregister: false,
    shouldFocusError: true,
    context: undefined,
    resolver: undefined,
  });
  const requiredMessage = useTranslation('errors.input.required');
  const setErrors = useCallback(
    (errorsFragment: GenericErrorType[]) => {
      const parsedErrors = parseErrors(errorsFragment);
      parsedErrors?.forEach(([field, message]) => {
        if (field === 'dateOfBirth') {
          setError('dateOfBirth.day' as never, {
            type: 'manual',
            message: `errors.${message}`,
          });
          return;
        }
        setError(field, { type: 'manual', message: `error.${message}` });
      });
    },
    [setError],
  );

  return {
    formState,
    control,
    errors: formState?.errors,
    trigger,
    register,
    watch,
    setError,
    clearErrors,
    setErrors,
    setValue,
    handleSubmit,
    getValues,
    required: (
      field: Path<T>,
      isRequired = true,
      options: RegisterOptions = {},
    ) => {
      const handlers = register(field, {
        required: { value: isRequired, message: requiredMessage },
        ...options,
      });
      const onChange: UseFormRegisterReturn['onChange'] = async (event) => {
        clearErrors('global' as Path<T>);
        handlers.onChange(event);
      };
      return { ...handlers, onChange, 'data-testid': field };
    },
    optional: (field: Path<T>) => ({
      ...register(field),
      'data-testid': field,
    }),
  };
};

export default useForm;
