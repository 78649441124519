/* eslint-disable no-restricted-imports */
'use client';
import { ThemeProvider as StyledProvider } from 'styled-components';
import StepByStepAuthProvider from '~/client/components/Auth/Authenticate/AuthSteps/context/StepByStepAuthProvider';
import LegacyStyledComponentsPage from '~/client/containers/LegacyStyledComponentsPage';
import useSite from '~/client/hooks/useSite';
import CaptchaProvider from '~/client/shared/Providers/CaptchaProvider';
import getCurrentTheme from '~/shared/utils/theme/getCurrentTheme';

const SignUpProvider: FC = ({ children }) => {
  const { site } = useSite();
  return (
    <LegacyStyledComponentsPage>
      <CaptchaProvider>
        <StepByStepAuthProvider>
          <StyledProvider
            theme={getCurrentTheme(
              site,
              site === 'livecasino' ? 'dark' : 'light',
            )}
          >
            {children}
          </StyledProvider>
        </StepByStepAuthProvider>
      </CaptchaProvider>
    </LegacyStyledComponentsPage>
  );
};

export default SignUpProvider;
