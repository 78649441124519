'use client';
import { useEffect, useState } from 'react';
import { Loader, mergeClassnames } from '@heathmont/moon-core-tw';
import useRouter from '~/client/hooks/router/useRouter';
import Translate from '~/client/shared/i18n/Translate';

const LoadingWrapper: FC = ({ children }) => {
  const [loading, setLoading] = useState(true);
  const [showButton, setShowButton] = useState(false);
  const router = useRouter();
  const queryHandle = router.query.reg as Connection;

  useEffect(() => {
    const buttonTimeoutId = setTimeout(() => {
      setShowButton(true);
    }, 5000);

    return () => clearTimeout(buttonTimeoutId);
  }, []);

  return (
    <>
      {queryHandle && loading && (
        <div className="absolute inset-0 flex items-center justify-center z-10 bg-goku">
          <div className="flex flex-col items-center justify-center gap-4 ">
            <Loader />
            <Translate id="general.redirect-popup" />
            <div className="h-10">
              <button
                onClick={() => setLoading(false)}
                className={mergeClassnames(
                  'px-4 py-2 bg-piccolo text-goku rounded hidden',
                  showButton && 'block',
                )}
              >
                <Translate id="general.close" />
              </button>
            </div>
          </div>
        </div>
      )}
      {children}
    </>
  );
};

export default LoadingWrapper;
