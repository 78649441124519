import { useEffect, useState } from 'react';
import useAgentData from '~/client/hooks/useAgentData';
import Cookie from '~/shared/utils/Cookie';
import useRouter from './router/useRouter';

type Props = {
  name: string;
  language?: 'en' | 'all';
  device?: 'mobile' | 'desktop' | 'all';
};

const useAbTest = ({ name, language = 'all', device = 'all' }: Props) => {
  const { locale } = useRouter();
  const { isVariant1Active, isVariant2Active } = useOptimizeAbtest(name);
  const showForAll = language === 'all' || language === locale;
  const showOnDevice = useIsVisibleForDevice(device);
  const showVariant1 = isVariant1Active && showForAll && showOnDevice;
  const showVariant2 = isVariant2Active && showForAll && showOnDevice;
  return { showVariant1, showVariant2 };
};

const useOptimizeAbtest = (testName: string) => {
  const [variant, setVariant] = useState(Cookie.get(testName) || null);
  useEffect(() => {
    window.addEventListener(testName, ((e: CustomEvent<string>) => {
      setVariant(e.detail);
      Cookie.set(testName, e.detail);
    }) as EventListener);
  }, [testName]);
  const isExperienceActive = variant === 'variant1';
  const isVariant1Active = variant === 'variant1';
  const isVariant2Active = variant === 'variant2';
  return { variant, isExperienceActive, isVariant1Active, isVariant2Active };
};

const useIsVisibleForDevice = (device: Props['device']) => {
  const { isMobile, isDesktop } = useAgentData();
  switch (device) {
    case 'desktop':
      return isDesktop;
    case 'mobile':
      return isMobile;
    default:
      return true;
  }
};

export default useAbTest;
